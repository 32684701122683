import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesGame: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game Modes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_modes.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Game Modes</h1>
          <h2>A guide showcasing all game modes available in AFK Journey.</h2>
          <p>
            Last updated: <strong>23/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Game modes" />
        <StaticImage
          src="../../../images/afk/generic/beginner_14.webp"
          alt="Guides"
        />
        <p>
          AFK Journey even on its release has a ton of various gamemodes
          available. In this guide we will only list them all without going into
          too much details, but soon we will release detailed guides for each!
        </p>
        <SectionHeader title="Game modes list" />
        <p>
          The section below gives a brief overview of the mode and the moment
          you unlock it.
        </p>
        <div className="afk-modes alt">
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_15.webp"
              alt="Guides"
            />
            <h5>World Map</h5>
            <p>
              Instead of jumping from stage to stage like in AFK Arena, the main
              story progression has been replaced by an{' '}
              <strong>open world experience</strong>. You can roam around it
              while doing quests, kill enemies, find treasure, gather materials,
              solve puzzles etc!
            </p>
            <p>
              The world is still split into areas and chapters and you have to
              finish the quests within each area to progress further.
            </p>
            <p className="unlock">
              Unlocks: <strong>Default</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_16.webp"
              alt="Guides"
            />
            <h5>AFK Stages</h5>
            <p>
              <strong>
                AFK Stages are basically battles against ever-increasing in
                power enemies
              </strong>
              . To unlock most of the other modes or game functionalities you
              have to reach certain stages in this mode first.
            </p>
            <p>
              The stage you've reached affects your{' '}
              <strong>'idle' rewards</strong>, so always try to progress here as
              deep as you can. Thankfully, this mode has an auto-challenge
              feature available which allows you to automatically challenge the
              next stage after beating the current one - so you don't have to
              sit and and constantly press next button for hours if your team is
              strong enough.
            </p>
            <p>
              The current max level stage you can reach on PTR is stage{' '}
              <strong>1125</strong> and by default you have gather the afk loot
              every <strong>12h</strong> (but this can be increased using the
              achievement system).
            </p>
            <p className="unlock">
              Unlocks: <strong>Tutorial</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_17.webp"
              alt="Guides"
            />
            <h5>Dream Realm</h5>
            <p>
              Dream Realm is a <strong>boss rush</strong> mode where you compete
              against every player on your server in who will deal the most
              damage to it.
            </p>
            <ul>
              <li>There are 4 bosses in total and they change every day,</li>
              <li>
                The more damage you do the boss, the better your score and the
                better the rewards you will obtain at the end of the day,
              </li>
              <li>
                Each boss has 6 available difficulties and once you kill him in
                one, you will advance to the next which makes the fight harder,
                but also gives you more points/better rewards,
              </li>
              <li>
                For killing each boss for the first time on any of the available
                difficulties you will obtain{' '}
                <StaticImage
                  src="../../../images/afk/icons/currency_summon.png"
                  alt="Invite Letter"
                />{' '}
                <strong>Invite Letters</strong>
              </li>
              <li>
                When a boss comes back in the next cycle, you will instantly
                start at the highest difficulty you've reached - so you don't
                have to waste time on killing him again on the lower ones.
              </li>
            </ul>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 16</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_18.webp"
              alt="Guides"
            />
            <h5>Honor Duel</h5>
            <p>
              Honor Duel is a <strong>fair play PVP mode</strong> where you can
              challenge other players and fight on even ground - you're not
              using your heroes, but the ones given to you by the game via a
              mode-specific card/shop system.
            </p>
            <p>
              While building your team you have to focus on synergies between
              heroes and who counters who. As here both sides have to 'place'
              the heroes on the battlefield in waves, so you can see what your
              enemy is planning and try to counter him - but watch out as he can
              do the same so don't be too fast with showing your strong hand.
            </p>
            <p>
              The mode has a weekly reward track that gives you rewards for
              achieving wins.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 16</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_19.webp"
              alt="Guides"
            />
            <h5>Arena</h5>
            <p>
              The Arena is another PVP mode, but here you use your own roster of
              heroes and fight against defense teams setup by other players.
            </p>
            <p>
              For winning a fight you receive rank points and lose them by
              losing a fight. As you gain points, you will progress through the
              League system, starting from Novice and ending on Champion. The
              higher your league, the bigger daily and weekly rewards you will
              receive.
            </p>
            <p>
              The battles are a team vs team - there's no 3vs3 PVP yet in the
              game.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 20</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_20.webp"
              alt="Guides"
            />
            <h5>Arcane Labyrinth</h5>
            <p>
              The good old Labyrinth makes a comeback in AFK Journey but as with
              the rest of the game, they added some improvements to this mode
              too.
            </p>
            <p>
              The gameplay itself also has slightly changed as now you enter a
              room and select one of two other rooms - each will give you a
              different reward for beating the enemy hiding inside.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 100</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_21.webp"
              alt="Guides"
            />
            <h5>Battle Drills</h5>
            <p>
              Battle Drills is a competitive available only to Guilds that is
              very similar to the Abyssal Expedition though on a much smaller
              and more manageable scale.
            </p>
            <p>
              The goal of this mode is the progress through the monster-infested
              map by killing scouts, camps and then bosses. You can gather
              various buffs for conquering strongholds that will make your
              heroes stronger, making it easier to push deeper into the enemy
              territory.
            </p>
            <p>
              Coordination is important if you want to achieve the highest
              rankings!
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 121</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/beginner_22.webp"
              alt="Guides"
            />
            <h5>Legend Trial</h5>
            <p>
              Legend Trial is basically renamed Factional Tower system known
              from AFK Arena.
            </p>
            <p>
              Different Trials are opened based on the day of the week and you
              can obtain Acorns, Essence and materials needed to Ascend
              Artifacts from here.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 166</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/primal.webp"
              alt="Guides"
            />
            <h5>Primal Lord</h5>
            <p>
              Primal Lords are boss monsters that spawn on the map and the whole
              server has to work together to defeat them. The faster you manage
              to do that, the better rewards everyone will obtain. This is a
              cyclical event so various Primal Bosses will spawn from time to
              time on the map.
            </p>
            <p className="unlock">
              Unlocks: <strong>cyclical event</strong>
            </p>
          </div>
          <div className="column">
            <StaticImage
              src="../../../images/afk/generic/trial.webp"
              alt="Guides"
            />
            <h5>Trial of Abyss</h5>
            <p>
              Trial of Abyss unlocks once you finish the initial story campaign
              and clear all 1125 AFK Stages, but also the server needs to be at
              least 18 days old. The mode is basically a dungeon you have to
              climb down and beat a team guarding each floor.
            </p>
            <p>
              Here you will encounter teams featuring multiple{' '}
              <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
              characters who will test you.
            </p>
            <p className="unlock">
              Unlocks: <strong>AFK Stage 1125</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesGame;

export const Head: React.FC = () => (
  <Seo
    title="Game Modes | AFK Journey | Prydwen Institute"
    description="A guide showcasing all game modes available in AFK Journey."
    game="afk"
  />
);
